import React from 'react';

import SEO from '@components/SEO';
import { routeToHome } from '@helpers';

const Frontend = () => {
  routeToHome();
  return <SEO image={`static/skill-verified-og-frontend.jpg`} />;
};

export default Frontend;
